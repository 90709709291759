<template>
  <BlankLayout>
    <v-layout align-center justify-center>
      <div>
        <div style="text-align: center">
          <img
            src="@/assets/logo.png"
            width="200"
            style="padding-bottom: 20px"
          />
        </div>
        <div style="text-align: center">
          <span style="white-space: pre-wrap" v-if="alert !== null">
            {{ alert }}
          </span>
          <div class="mt-10 mb-5" v-else>
            <h1 style="white-space: pre-wrap">
              {{ $t('views.logout.loggedOut.heading') }}
            </h1>
            <p style="white-space: pre-wrap">
              {{ $t('views.logout.loggedOut.text') }}
            </p>
            <v-btn class="mx-2 error" @click="reportWasntMe">{{
              $t('views.logout.loggedOut.no')
            }}</v-btn>
            <v-btn
              class="mx-2 success"
              @click="alert = $t('views.logout.message')"
              >{{ $t('views.logout.loggedOut.yes') }}</v-btn
            >
          </div>
          <br /><br />
          <a href="https://fair-votes.com/live">FairVotes LIVE</a>
        </div>
      </div>
    </v-layout>
  </BlankLayout>
</template>

<script>
import BlankLayout from '@/components/layouts/Blank.vue'

export default {
  name: 'Logout',

  components: {
    BlankLayout,
  },

  data() {
    return {
      title: this.$t('views.logout.title'),
      alert: this.$t('views.logout.message'),
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.title = this.$t('views.logout.title')
      document.title = this.title
    },
  },

  created: function () {
    document.title = this.title

    if (this.$mystore.state.report_token !== null) {
      this.alert = null
    }
  },

  methods: {
    async reportWasntMe() {
      await fetch(`${process.env.VUE_APP_API_URL}/voter/report/wasnt-me`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: this.$mystore.state.report_token,
        }),
      })
        .then(() => {
          this.$mystore.removeReportToken()

          this.alert = this.$t('views.logout.loggedOut.alert')
        })
        .catch(response => {
          console.error(response)

          alert(this.$t('views.logout.alert'))
        })
    },
  },
}
</script>
